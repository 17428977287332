import React from "react";
import { Container } from "react-bootstrap";

// Create React page called ErrorPage which uses bootstrap for styling
// The page should display a message that says "Oops! Something went wrong."
// The page should display a button that says "Go back to home page"
// The button should link to the home page
// The page should display a button that says "Go back to previous page"
// The button should link to the previous page

const ErrorPage = () => {
    return (
        <div className="d-flex align-items-center justify-content-center vh-100">
            <div className="text-center">
                <h1 className="error-title display-1 fw-bold">
                    <span className="text-danger">Opps!</span>
                </h1>
                <p className="lead">Something went wrong!</p>
                <a href="/" className="btn btn-primary">
                    Go Home
                </a>
            </div>
        </div>
    );
};

export default ErrorPage;
