import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navigation from "./Navigation";
import Sidebar from "./sidebar/Sidebar";
import { CompanyContext } from "./CompanyContext";
import { EmployeeContext } from "./EmployeeContext";
import ToastContainer from "./ToastContainer";
import { ToastsProvider } from "../services/Toasts.service";
import FunctionProvider from "../services/Function.service";
import { usePersistedState } from "../services/State.service";
import SetOutOfOfficeModal from "./outOfOffice/SetOutOfOfficeModal";
import { TranslationsProvider } from "../translation/Translations";
import EndSessionModal from "./EndSessionModal";
import { useQueryClient } from "react-query";
import { useEmployeeQuery } from "../api/Employee";
import { Link, Outlet } from "react-router-dom";
import EmployeeDto from "../dto/Employee";
import CompanyDto from "../dto/Company";
import httpService from "../services/Http.service";
import authService from "../services/Auth.service";
import { useIdleTimer } from "react-idle-timer";

const Layout = () => {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [currentCompany, setCurrentCompany] = usePersistedState("company", "");
    const [showEndSessionModal, setShowEndSessionModal] = useState(false);
    const [showOutOfOffice, setShowOutOfOffice] = useState(false);
    const [companies, setCompanies] = useState<CompanyDto[]>([]);

    const queryClient = useQueryClient();

    useEffect(() => {
        authService.subscribe(() => populateState());
        populateState();
    }, []);

    const populateState = async () => {
        const user = await authService.getUser();
        var companies = await httpService.get<CompanyDto[]>(`company/${user?.user_id}`);
        setCompanies(companies.data);
        queryClient.invalidateQueries("employee");
        if (user && currentCompany === "" && user.default_company) {
            setCurrentCompany(user.default_company);
        }
    };

    const employeeQuery = useEmployeeQuery(
        authService._user?.profile.user_id as string,
        currentCompany
    );
    const handleBackdropClick = () => {
        if (sidebarOpen) {
            setSidebarOpen(false);
            const burger = document.querySelector(".ham.hamRotate.active");
            if (burger) burger.classList.toggle("active");
        }
    };

    const logout = () => {
        authService.signOut();
    };

    const evaluateEnvToBool = (variable: string) => variable === "true";

    const handleOnIdle = () => {
        if (evaluateEnvToBool(import.meta.env.REACT_APP_LOGOUT_ON_TIMEOUT)) {
            authService.endSessionSignOut();
        } else setShowEndSessionModal(true);
    };

    let idleTimeout = parseInt(import.meta.env.REACT_APP_TIMEOUT || "");
    if (isNaN(idleTimeout)) idleTimeout = 20 * 60 * 60 * 1000;
    const { activate } = useIdleTimer({
        timeout: idleTimeout,
        throttle: 500,
        onPrompt: handleOnIdle,
        promptBeforeIdle: 1000
    });

    const refreshWindow = () => {
        activate();
        setShowEndSessionModal(false);
        window.location.reload();
    };

    return (
        <CompanyContext.Provider
            value={{
                currentCompany: currentCompany,
                setCurrentCompany: setCurrentCompany,
                availableCompanies: companies
            }}
        >
            <EmployeeContext.Provider value={employeeQuery.data || ({} as EmployeeDto)}>
                <TranslationsProvider language={employeeQuery?.data?.language || "en"}>
                    <FunctionProvider>
                        <Container fluid>
                            <Navigation
                                toggleSidebar={() => setSidebarOpen(!sidebarOpen)}
                                setCurrentCompany={setCurrentCompany}
                                onLanguageChange={() => queryClient.invalidateQueries("employee")}
                                setShowOutOfOffice={setShowOutOfOffice}
                                companies={companies}
                            />
                            <Sidebar open={sidebarOpen} />
                            <ToastsProvider>
                                <div
                                    className="sidebar-backdrop"
                                    onClick={() => handleBackdropClick()}
                                />
                                <div className="layout-content">
                                    <Outlet />
                                </div>
                                <ToastContainer />
                            </ToastsProvider>
                            <footer className="d-flex align-items-center justify-content-center footer">
                                © 2019 - {new Date().getFullYear()} Alna Business{" "}
                                <Link to="/about" className="footer-version">
                                    • {APP_VERSION}
                                </Link>
                            </footer>
                            <EndSessionModal
                                show={showEndSessionModal}
                                refreshWindow={refreshWindow}
                                logout={logout}
                            />
                            {employeeQuery?.data?.id !== "" ? (
                                <SetOutOfOfficeModal
                                    show={showOutOfOffice}
                                    setShow={setShowOutOfOffice}
                                    outOfOffice={employeeQuery?.data?.outOfOffice || false}
                                    language={employeeQuery?.data?.language || "en"}
                                    refreshEmployee={() =>
                                        queryClient.invalidateQueries("employee")
                                    }
                                    employeeId={employeeQuery?.data?.id || ""}
                                />
                            ) : null}
                        </Container>
                    </FunctionProvider>
                </TranslationsProvider>
            </EmployeeContext.Provider>
        </CompanyContext.Provider>
    );
};

export default Layout;
