import React, { useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import ManagerSidebarItems from "./ManagerSidebarItems";
import AdministrationSidebarItems from "./AdministrationSidebarItems";
import BaseMenuItems from "./BaseMenuItems";
import "../../extensions";
import { useLocation } from "react-router-dom";

interface SidebarProps {
    open: boolean;
}

const evaluateEnvToBool = (variable: string) => variable === "true";

const Sidebar = (props: SidebarProps) => {
    const location = useLocation();
    const activeTab = evaluateEnvToBool(import.meta.env.REACT_APP_MULTITENANT)
        ? location.pathname.removeTenant()
        : location.pathname;

    return (
        <Nav
            className={`flex-column sidebar ${props.open ? "open" : ""}`}
            variant="pills"
            activeKey={activeTab}
        >
            <BaseMenuItems />
            <ManagerSidebarItems />
            <AdministrationSidebarItems />
        </Nav>
    );
};

export default Sidebar;
